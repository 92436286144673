var render = function () {
  var _vm$task, _vm$task2, _vm$task3, _vm$task4, _vm$task4$task, _vm$task4$task$data, _vm$task5, _vm$task5$task, _vm$task5$task$data, _vm$task6, _vm$task7, _vm$task7$observer, _vm$task7$observer$da, _vm$task8, _vm$task8$observer, _vm$task8$observer$da, _vm$task9, _vm$task9$observer, _vm$task9$observer$da, _vm$task10, _vm$task10$observer, _vm$task10$observer$d, _vm$task11, _vm$task11$team, _vm$task12, _vm$task12$team, _vm$task13, _vm$task13$team, _vm$task14, _vm$task15;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "task-item"
  }, [_c('div', {
    ref: "taskCard",
    staticClass: "custom-font task-handle",
    attrs: {
      "data-task-id": _vm.task.id
    }
  }, [_c('b-row', {
    class: 'title-gap'
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.sidebar-right",
      modifiers: {
        "sidebar-right": true
      }
    }],
    on: {
      "click": function click($event) {
        return _vm.$emit('show-sidebar', _vm.task);
      }
    }
  }, [_c('div', [(_vm$task = _vm.task) !== null && _vm$task !== void 0 && _vm$task.overdue ? _c('b-badge', {
    staticClass: "mb-1",
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v("Over Due")]) : _vm._e()], 1), _c('span', {
    attrs: {
      "title": (_vm$task2 = _vm.task) === null || _vm$task2 === void 0 ? void 0 : _vm$task2.title
    }
  }, [_vm._v(" " + _vm._s(_vm.truncateTitle((_vm$task3 = _vm.task) === null || _vm$task3 === void 0 ? void 0 : _vm$task3.title)) + " ")]), _vm.isSubtask ? _c('div', {
    staticClass: "mt-1 mb-1",
    attrs: {
      "title": (_vm$task4 = _vm.task) === null || _vm$task4 === void 0 ? void 0 : (_vm$task4$task = _vm$task4.task) === null || _vm$task4$task === void 0 ? void 0 : (_vm$task4$task$data = _vm$task4$task.data) === null || _vm$task4$task$data === void 0 ? void 0 : _vm$task4$task$data.title
    }
  }, [_c('b-badge', {
    attrs: {
      "variant": "light-warning"
    }
  }, [_vm._v(_vm._s(_vm.truncateTitle((_vm$task5 = _vm.task) === null || _vm$task5 === void 0 ? void 0 : (_vm$task5$task = _vm$task5.task) === null || _vm$task5$task === void 0 ? void 0 : (_vm$task5$task$data = _vm$task5$task.data) === null || _vm$task5$task$data === void 0 ? void 0 : _vm$task5$task$data.title)))])], 1) : _vm._e(), (_vm$task6 = _vm.task) !== null && _vm$task6 !== void 0 && _vm$task6.observer ? _c('span', {
    staticClass: "ml-1"
  }, [_c('b-avatar', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.top",
      value: "Observer: ".concat((_vm$task7 = _vm.task) === null || _vm$task7 === void 0 ? void 0 : (_vm$task7$observer = _vm$task7.observer) === null || _vm$task7$observer === void 0 ? void 0 : (_vm$task7$observer$da = _vm$task7$observer.data) === null || _vm$task7$observer$da === void 0 ? void 0 : _vm$task7$observer$da.name, " (").concat((_vm$task8 = _vm.task) === null || _vm$task8 === void 0 ? void 0 : (_vm$task8$observer = _vm$task8.observer) === null || _vm$task8$observer === void 0 ? void 0 : (_vm$task8$observer$da = _vm$task8$observer.data) === null || _vm$task8$observer$da === void 0 ? void 0 : _vm$task8$observer$da.email, ")"),
      expression: "\n                  `Observer: ${task?.observer?.data?.name} (${task?.observer?.data?.email})`\n                ",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    attrs: {
      "src": ((_vm$task9 = _vm.task) === null || _vm$task9 === void 0 ? void 0 : (_vm$task9$observer = _vm$task9.observer) === null || _vm$task9$observer === void 0 ? void 0 : (_vm$task9$observer$da = _vm$task9$observer.data) === null || _vm$task9$observer$da === void 0 ? void 0 : _vm$task9$observer$da.avatar) === '' ? '/avatar.svg' : (_vm$task10 = _vm.task) === null || _vm$task10 === void 0 ? void 0 : (_vm$task10$observer = _vm$task10.observer) === null || _vm$task10$observer === void 0 ? void 0 : (_vm$task10$observer$d = _vm$task10$observer.data) === null || _vm$task10$observer$d === void 0 ? void 0 : _vm$task10$observer$d.avatar,
      "size": "26"
    }
  })], 1) : _vm._e()])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('div', {
    staticClass: "mt-1"
  }, [_vm._l((_vm$task11 = _vm.task) === null || _vm$task11 === void 0 ? void 0 : (_vm$task11$team = _vm$task11.team) === null || _vm$task11$team === void 0 ? void 0 : _vm$task11$team.data, function (member, index) {
    return _c('span', {
      key: index
    }, [index <= 2 ? [_c('b-avatar', {
      attrs: {
        "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
        "size": "26"
      }
    })] : _vm._e()], 2);
  }), ((_vm$task12 = _vm.task) === null || _vm$task12 === void 0 ? void 0 : (_vm$task12$team = _vm$task12.team) === null || _vm$task12$team === void 0 ? void 0 : _vm$task12$team.data.length) > 3 ? _c('span', [_vm._v(" + " + _vm._s(((_vm$task13 = _vm.task) === null || _vm$task13 === void 0 ? void 0 : (_vm$task13$team = _vm$task13.team) === null || _vm$task13$team === void 0 ? void 0 : _vm$task13$team.data.length) - 3) + " more ")]) : _vm._e(), _c('b-button', {
    staticClass: "btn-icon rounded-circle btn-sm ml-1",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('show-member', _vm.task);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserPlusIcon"
    }
  })], 1)], 2)]), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('div', [_c('b-dropdown', {
    ref: "task-d-down-".concat((_vm$task14 = _vm.task) === null || _vm$task14 === void 0 ? void 0 : _vm$task14.id),
    attrs: {
      "variant": "link",
      "no-caret": "",
      "id": "task-d-down-".concat((_vm$task15 = _vm.task) === null || _vm$task15 === void 0 ? void 0 : _vm$task15.id)
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "text-body align-middle mr-25 mt-1",
          attrs: {
            "icon": "MoreVerticalIcon",
            "size": "16"
          }
        })];
      },
      proxy: true
    }])
  }, [_vm.$permissionAbility(_vm.MILESTONE_BOARD_TASK_DELETE, _vm.permissions) ? [_c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.$emit('delete-task', _vm.task);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50 board-trash-icon",
    attrs: {
      "icon": "Trash2Icon"
    }
  }), _c('span', [_vm._v("Delete")])], 1)] : _vm._e()], 2)], 1)])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }