var render = function () {
  var _vm$phase, _vm$phase2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-1 phase-card-title"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('span', {
    staticClass: "ml-1 phase-title custom-font-color mr-1"
  }, [_vm._v(_vm._s((_vm$phase = _vm.phase) === null || _vm$phase === void 0 ? void 0 : _vm$phase.title))]), _vm.getPhaseTypeVariant(_vm.phase) ? _c('b-badge', {
    staticClass: "mr-1",
    attrs: {
      "variant": _vm.getPhaseTypeVariant(_vm.phase)
    }
  }, [_vm._v(" " + _vm._s(_vm.getPhaseTypeLabel(_vm.phase)) + " ")]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [!((_vm$phase2 = _vm.phase) !== null && _vm$phase2 !== void 0 && _vm$phase2.is_backlog) ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn-sm",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('edit-phase', _vm.phase);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "Edit2Icon"
    }
  })], 1) : _vm.$permissionAbility(_vm.MILESTONE_BOARD_TASK_CREATE, _vm.permissions) && !_vm.isSubtask ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn-sm",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('create-backlog');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  })], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }