<template>
  <div class="mb-1 phase-card-title">
      <b-row>
        <b-col cols="9">
          <span class="ml-1 phase-title custom-font-color mr-1">{{ phase?.title }}</span>
          <b-badge
              v-if="getPhaseTypeVariant(phase)"
              :variant="getPhaseTypeVariant(phase)"
              class="mr-1"
          >
            {{ getPhaseTypeLabel(phase) }}
          </b-badge>
        </b-col>

        <b-col cols="3">
          <b-button
              v-if="!phase?.is_backlog"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-sm"
              @click="$emit('edit-phase', phase)"
          >
            <feather-icon icon="Edit2Icon" />
          </b-button>
          <b-button
              v-else-if="$permissionAbility(MILESTONE_BOARD_TASK_CREATE, permissions) && !isSubtask"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-sm"
              @click="$emit('create-backlog')"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-col>
      </b-row>
    </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCardSubTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BModal,
  BRow,
  BSpinner, VBToggle, VBTooltip
} from 'bootstrap-vue';
import {
  MILESTONE_BOARD_TASK_CREATE,
} from "@/helpers/permissionsConstant";
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapGetters } from 'vuex';

export default {
  name: 'Phase',
  components: {
    BBadge,
    BButton,
    BCardSubTitle,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    BModal,
    BSpinner,
  },
  props: {
    phase: {
      type: Object,
      required: true
    },
    isSubtask: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      MILESTONE_BOARD_TASK_CREATE,
    };
  },
  computed: {
    ...mapGetters({
      permissions: 'userModule/getPermissions'
    }),
  },
  methods: {
    getPhaseTypeVariant(phase) {
      if (phase?.is_backlog) {
        return 'light-info';
      }
      return phase?.is_final ? 'light-danger' : 'light-primary';
    },
    getPhaseTypeLabel(phase) {
      if (phase?.is_backlog) {
        return 'Initial';
      }
      return phase?.is_final ? 'Final' : 'General';
    },
  }
};
</script>

<style scoped lang="scss">
/* Add styles specific to Phase.vue component here */
.b-card-sub-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.phase-card-title {
  cursor: grab; /* Set a custom cursor style when hovering over the phase-list-main-card elements */
}

.phase-card-title:active {
  cursor: grabbing; /* Set a different cursor style when the element is actively dragged */
}
.phase-title{
  color: #636363;
  font-weight: bold;
}
</style>
